.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: bisque;
  width: 32vw;
  height: 32vh;
  margin: auto;
  box-shadow: 3px 5px #d0d9da;
}

.card img {
  margin-top: 0;
  width: 20vh;
  padding: 10px;
  float: left;
  position: absolute;
}

.card h2 {
  margin-top: 1rem;
  margin-left: 24vh;
  font-weight: bold;
}

.card p {
  margin-top: 0.5rem;
  margin-left: 24vh;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

